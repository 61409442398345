<template>
   <div class="supplier" v-loading.fullscreen.lock="fullscreen" element-loading-text="请求数据中">
     <el-form label-width="70px"> 
      <el-row>
         <el-col :span="3">
           <el-form-item label-width="0px">
             <el-input 
              clearable 
              @clear="search()" 
              placeholder="请输入供应商编号" 
              v-model.trim="pageInfo.keyword" 
              @keyup.enter.native = "find"
             ></el-input>
           </el-form-item>
         </el-col>
         <el-col :span="6" :offset="1">
           <el-button type="primary" icon="el-icon-search" @click="find">查询</el-button>
           <el-button type="primary" icon="el-icon-circle-plus-outline" @click="Add">新增供应商</el-button>
           <el-button type="primary" icon="el-icon-upload" @click="Import">导入供应商</el-button>
         </el-col>
        </el-row>
     </el-form>

     <el-table
       ref="multipleTable"
       :data="tableData"
       border
       tooltip-effect="dark"
       style="width: 100%; marginTop:20px"
       :row-style="rowClass"
       :header-cell-style="getRowClass"
       @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column label="供应商编号" prop="supplierNo" align="center"></el-table-column>
        <el-table-column label="供应商名称" prop="supplierName" align="center"></el-table-column>
        <el-table-column label="地址" prop="companyAddress" align="center"></el-table-column>
        <el-table-column label="公司电话" prop="companyTelephone" align="center"></el-table-column>
        <el-table-column label="联系人" prop="contact" align="center"></el-table-column>
        <el-table-column label="联系电话" prop="contactTelephone" align="center"></el-table-column>
        <el-table-column label="网址" align="center">
          <template slot-scope="scope">
             <el-link type="primary" :href="scope.row.website" target="_blank">{{scope.row.website}}</el-link>
          </template>
        </el-table-column>
        <el-table-column label="备注" prop="tips" align="center"></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-link :underline="false" type="primary" icon="el-icon-edit" @click="asyncEdit(scope.row)">编 辑</el-link> 
            <el-link :underline="false" style="padding: 0 8px" icon="el-icon-bank-card" type="success" @click="settlementAccount(scope.row.id)">结算账户</el-link> 
            <el-link :underline="false" type="danger" icon="el-icon-delete" @click="asyncDelete(scope.row.id)">删 除</el-link> 
          </template>
        </el-table-column>
     </el-table>
     <!-- 分页区域 -->
     <div class="block" style="textAlign:center;marginTop:20px;paddingRight:20px">
       <el-pagination
         @size-change="handleSizeChange"
         @current-change="handleCurrentChange"
         :current-page="pageInfo.current"
         :page-sizes="[10, 20, 30, 50]"
         :page-size="pageInfo.pageSize"
         layout="total, sizes, prev, pager, next, jumper"
         :total="pageInfo.total">
       </el-pagination>
    </div>

    <!-- 新增供应商 -->
    <el-dialog
      center
      width="25%"
      title="新增供应商"
      :visible.sync="addVisible"
     >
     <el-form :model="supplierForm" :rules="supplierRules" ref="supplierFormRef" label-width="110px" class="demo-ruleForm">
       <el-form-item label="供应商名称：" prop="supplierName">
         <el-input 
          clearable 
          style="width: 80%" 
          placeholder="请填写供应商名称"
          v-model="supplierForm.supplierName" 
         ></el-input>
       </el-form-item>
       <el-form-item label="供应商代码：" prop="supplierCode">
         <el-input 
           clearable
           style="width: 80%"
           placeholder="请填写供应商代码"
           v-model="supplierForm.supplierCode" 
         ></el-input>
       </el-form-item>
       <el-form-item label="产品类型：" prop="productType">
         <el-select 
          clearable 
          style="width: 80%" 
          placeholder="请选择产品类型"
          v-model="supplierForm.productType"
         >
           <el-option
            v-for="item in productTypeList"
            :key="item.id"
            :label="item.value"
            :value="item.id"
           ></el-option>
         </el-select>
       </el-form-item>
       <el-form-item label="结算方式：" prop="settlementType">
         <el-select 
          clearable 
          style="width: 60%" 
          placeholder="请选择结算方式"
          v-model="supplierForm.settlementType" 
         >
          <el-option
            v-for="item in settlementTypeList"
            :key="item.id"
            :label="item.value"
            :value="item.id"
          >
          </el-option>
         </el-select>
         <el-button type="text" style="font-size:16px; margin-left: 25px" @click="manageType">类型管理</el-button>
       </el-form-item>
       <el-form-item label="公司地址：" >
          <el-input v-model="supplierForm.companyAddress" clearable style="width: 80%" placeholder="请填写地址信息"></el-input>
       </el-form-item>
       <el-form-item 
         label="公司电话：" 
         prop="companyTelephone"
         :rules="[
           { required: true, message: '请输入公司电话', trigger: 'blur' },
         ]"
         >
           <!-- { type: 'phone', message: '请输入正确的公司电话', trigger: ['blur', 'change'] } -->
         <el-input v-model.number="supplierForm.companyTelephone" clearable style="width: 80%" placeholder="请填写公司电话"></el-input>
       </el-form-item>
       <el-form-item label="联系人：" prop="contact">
         <el-input  v-model="supplierForm.contact" style="width: 80%" clearable placeholder="请填写联系人"></el-input>
       </el-form-item>
        <el-form-item label="联系电话：" prop="contactTelephone">
         <el-input v-model="supplierForm.contactTelephone" @blur="vaildPhone" oninput="value=value.replace(/[^0-9.]/g,'')" style="width: 80%" clearable placeholder="请填写联系电话"></el-input>
       </el-form-item>
       <el-form-item label="网址：" prop="website">
         <el-input  v-model="supplierForm.website" style="width: 80%" clearable placeholder="请填写网址"></el-input>
       </el-form-item>
        <el-form-item label="备注：" prop="tips">
         <el-input  v-model="supplierForm.tips" style="width: 80%" clearable placeholder="请输入信息进行备注"></el-input>
       </el-form-item>
       <el-form-item label="营业执照：">
         <el-upload
           class="upload-demo2"
           ref="upload"
           list-type="picture-card"
           :action="action2"
           :show-file-list="false"
           accecp="pdf/png"
           :on-change="handleChange2"
           :auto-upload="false"
           :on-success="handleSuccess2"
           :before-upload="beforeAvatarUpload"
           :file-list="fileList2"
           >
          <div style="overflow:hidden">
            <img v-if="add_fileUrl" :src="add_fileUrl" class="avatar" width='146px' height="146px">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </div>

           <!-- <img :src="add_fileUrl" alt="" style="width: 360px;"> -->
           <!-- <i class="el-icon-upload"></i> -->
           <!-- <div class="el-upload__text">点击上传营业执照</div> -->
           <p class="el-upload__tip_tip" slot="tip" style="color: red;text-align:left">
             <span>图片大小小于10M</span><br/>
             <span>仅支持 pdf/png格式图片</span>
           </p>
         </el-upload>       
       </el-form-item>
       <div style="text-align: center; margin-top: 30px">
         <el-button @click="addVisible = false">取 消</el-button>
         <el-button type="primary" @click="nextStep('supplierFormRef')">下一步</el-button>
       </div>
     </el-form>
    </el-dialog>

    <!-- 编辑供应商 -->
    <el-dialog
      center
      width="25%"
      title="编辑供应商"
      :visible.sync="editVisible"
      :modal-append-to-body="false"
     >
      <el-form :model="editSupplierForm" :rules="editSupplierRules" ref="editSupplierFormRef" label-width="110px" >
       <el-form-item label="供应商名称：" prop="supplierName">
         <el-input 
           clearable
           style="width: 80%" 
           placeholder="请填写供应商名称"
           v-model="editSupplierForm.supplierName" 
         ></el-input>
       </el-form-item>
       <el-form-item label="供应商代码：" prop="supplierCode">
         <el-input
          clearable
          style="width: 80%"
          placeholder="请填写供应商代码"
          v-model="editSupplierForm.supplierCode"
         ></el-input>
       </el-form-item>
       <el-form-item label="产品类型" prop="productType">
         <el-select
          clearable
          style="width: 80%"
          placeholder="请选择产品类型"
          v-model="editSupplierForm.productType"
         >
          <el-option
           v-for="item in productTypeList"
           :key="item.id"
           :label="item.value"
           :value="item.id"
          >
          </el-option>
         </el-select>
       </el-form-item>
       <el-form-item label="结算方式：" prop="settlementType">
         <el-select 
           clearable 
           style="width: 80%"
           v-model="editSupplierForm.settlementType" 
           placeholder="请选择结算方式"
          >
          <el-option
            v-for="item in settlementTypeList"
            :key="item.id"
            :label="item.value"
            :value="item.id"
          >
          </el-option>
         </el-select>
       </el-form-item>
       <el-form-item label="公司地址：" >
          <el-input v-model="editSupplierForm.companyAddress" clearable style="width: 80%" placeholder="请填写地址信息"></el-input>
       </el-form-item>
       <el-form-item 
        label="公司电话：" 
        prop="companyTelephone"
        :rules="[
          { required: true, message: '请输入公司电话', trigger: 'blur' },
        ]"
        >
        <!-- { type: 'phone', message: '请输入正确的公司电话', trigger: ['blur', 'change'] } -->
         <el-input v-model.number="editSupplierForm.companyTelephone" clearable style="width: 80%" placeholder="请填写公司电话"></el-input>
       </el-form-item>
       <el-form-item label="联系人：" prop="contact">
         <el-input  v-model="editSupplierForm.contact" style="width: 80%" clearable placeholder="请填写联系人"></el-input>
       </el-form-item>
        <el-form-item label="联系电话：" prop="contactTelephone">
         <el-input  v-model="editSupplierForm.contactTelephone" @blur="vaildPhone" clearable style="width: 80%" placeholder="请填写联系电话"></el-input>
       </el-form-item>
       <el-form-item label="网址：" prop="website">
         <el-input  v-model="editSupplierForm.website" clearable style="width: 80%" placeholder="请填写网址"></el-input>
       </el-form-item>
        <el-form-item label="备注：" prop="tips">
         <el-input  v-model="editSupplierForm.tips" clearable style="width: 80%" placeholder="请输入信息进行备注"></el-input>
       </el-form-item>
       <el-form-item label="营业执照：" prop="">
         <el-upload
          list-type="picture-card"
          ref="upload"
          class="upload-demo2"
          :action="action3"
          :show-file-list="false"
          accecp="pdf/png"
          :on-change="handleChange_Edit"
          :auto-upload="false"
          :before-upload="beforeUpload_Edit"
         >

          <div style="overflow:hidden">
            <img v-if="fileUrl" :src="fileUrl" class="avatar" width='146px' height="146px">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </div>

          <!-- <img src="https://img1.baidu.com/it/u=3771357394,1518226081&fm=15&fmt=auto" alt="" style="width: 360px;"> -->
          <!-- <img :src="fileUrl" alt="" style="width: 360px;">
          <i class="el-icon-upload"></i> -->
          <!-- <div class="el-upload__text">点击上传营业执照</div> -->
          <p class="el-upload__tip_tip" slot="tip" style="color: red;text-align:left">
            <span>图片大小小于10M</span><br/>
            <span>仅支持 pdf/png格式图片</span>
          </p>
         </el-upload>
         <!-- <div>
           <img :src="file_file" alt="" style="width: 360px; height: 150px">
         </div> -->
       </el-form-item>
       <div style="text-align: center; margin-top: 30px">
         <el-button @click="editVisible = false">取 消</el-button>
         <el-button type="primary" @click="edit('editSupplierFormRef')">编 辑</el-button>
       </div>
     </el-form>
    </el-dialog>

    <!-- 新增账户 -->
    <el-dialog
      center
      border
      width="63%"
      title="新增账户"
      :visible.sync="accountVisible"
     >
     <div>
       <el-divider></el-divider>
        <div style="text-align: left; margin-bottom: 10px">
          <el-button type="primary" icon="el-icon-circle-plus" @click="addAccount">新增账户</el-button>
        </div>
        <el-table :data="AddAccountList">
          <el-table-column label="结算账户编号" prop="accountNo" align="center"></el-table-column>
          <el-table-column label="账户名称" align="center">
            <template slot-scope="scope">
              <!-- :disabled="type == 1? true: false" -->
               <el-input
                clearable
                type="text"
                v-model="scope.row.accountName"
                placeholder="请输入账户名称"
               >  
               </el-input>
            </template>
          </el-table-column>
          <el-table-column label="账户类型" align="center">
            <template slot-scope="scope">
              <!-- :disabled="type == 1? true: false" -->
              <el-select
                clearable
                placeholder="请选择账户类型"
                v-model="scope.row.accountType"
              >
                <el-option
                  v-for="item in accountTypeList"
                  :label="item.name"
                  :value="item.id"
                  :key="item.id"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="开户机构" align="center">
            <template slot-scope="scope">
              <!-- :disabled="type == 1? true: false" -->
               <el-input
                clearable
                type="text"
                v-model="scope.row.bank"
                placeholder="请输入开户机构"
               >  
               </el-input>
            </template>
          </el-table-column>
          <el-table-column label="账号" align="center">
            <template slot-scope="scope">
              <!-- :disabled="type == 1? true: false" -->
              <el-input
                clearable
                type="text"
                placeholder="请输入账号"
                v-model="scope.row.bankAccount"
               >  
               </el-input>
            </template>
          </el-table-column>
          <el-table-column label="默认账号" width="250px" align="center">
            <template slot-scope="scope"> 
             <el-switch
               v-model="scope.row.defaultAccount"
               active-color="#13ce66"
               inactive-color="#ff4949"
               active-value = "1"
               inactive-value = "0"
               active-text="默认账号"
               inactive-text="非默认账号">
             </el-switch>
            </template>
          </el-table-column>
        <el-table-column label="状态" width="60px" align="center">
          <!-- v-if="scope.row.accountStatus == 0" -->
           <template slot-scope="scope">
             <span style="color:#13ce66">{{scope.row.accountStatus == 0 ? '启 用': '禁 用'}}</span>
           </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-link type="primary" @click="asyncBtn(scope.row)">确 定</el-link> 
              <el-link style="padding: 0 8px" type="success" @click="asyncDisable(scope.row.accountStatus)">
                <span v-if="scope.row.accountStatus == 0">禁 用</span>
                <span v-else>启 用</span>
              </el-link> 
            <el-link type="danger" @click="asyncDelete2(scope.$index, scope.row)">删 除</el-link> 
          </template>
        </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <div class="block" style="textAlign:center; marginTop:20px; paddingRight:20px">
           <el-pagination
             @size-change="handleSizeChange2"
             @current-change="handleCurrentChange2"
             :current-page="pageList.current"
             :page-sizes="[10, 20, 30, 50]"
             :page-size="pageList.pageSize"
             layout="total, sizes, prev, pager, next, jumper"
             :total="pageList.total">
           </el-pagination>
        </div>
     </div>
    </el-dialog>

    <!-- 导入供应商 -->
     <el-dialog
       center
       width="25%"
       title="导入供应商"
       :visible.sync="importVisible"
      >
      <div class="downloadbtnFat">
        <el-button 
          size="medium"
          class="downloadbtn"
          icon="el-icon-download" 
          @click="downloadBtn"
        >点击下载模板</el-button>
      </div>
       <el-upload
         class="upload-demo"
         drag
         ref="upload"
         :action="action"
         :show-file-list="true"
         accecp="xlsx"
         :on-change="handleChange"
         :auto-upload="false"
         :on-success="handleSuccess"
         :before-upload="beforeUpload"
         :file-list="fileList"
         >
         <i class="el-icon-upload"></i>
         <div class="el-upload__text">点击上传供应商文件</div>
         <p class="el-upload__tip" slot="tip">仅支持.xlsx格式文件</p>
      </el-upload>
      <div style="text-align: center; margin-top: 25px">
        <el-button @click="importCance" size="medium">取 消</el-button>
        <el-button type="primary" @click="asyncImport" size="medium">导入</el-button>
      </div>
     </el-dialog>

     <!-- 结算账户 -->
     <el-dialog
       center
       width="63%"
       title="结算账户"
       :visible.sync="settlementVisible"
      >
       <div>
         <el-divider></el-divider>
         <div style="text-align: left; margin-bottom: 10px">
           <el-button type="primary" icon="el-icon-circle-plus" @click="asyncSettlement">结算账户</el-button>
         </div>
         <el-table :data="settlementList" border>
           <el-table-column label="结算账户编号" prop="accountNo" align="center"></el-table-column>

           <el-table-column label="账户名称" align="center">
             <template slot-scope="scope">
               <!-- :disabled="type == 1 ? false : true" -->
               <el-input
                 clearable
                 type="text"
                 v-model="scope.row.accountName"
                 placeholder="请输入账户名称"
               >  
               </el-input>
             </template>
           </el-table-column>

           <el-table-column label="账户类型" align="center">
             <template slot-scope="scope">
               <!-- :disabled="type == 1 ? false : true" -->
               <el-select
                 clearable
                 placeholder="请选择账户类型"
                 v-model="scope.row.accountType"
               >
                 <el-option
                   v-for="item in accountTypeList"
                   :label="item.name"
                   :value="item.id"
                   :key="item.id"
                 ></el-option>
               </el-select>
             </template>
           </el-table-column>

          <el-table-column label="开户机构" align="center">
            <template slot-scope="scope">
              <!-- :disabled="type == 1 ? false : true" -->
              <el-input
                clearable
                type="text"
                v-model="scope.row.bank"
                placeholder="请输入开户机构"
               >  
              </el-input>
            </template>
          </el-table-column>

          <el-table-column label="账号" align="center">
             <template slot-scope="scope">
               <!-- :disabled="type == 1 ? false : true" -->
              <el-input
                clearable
                type="text"
                placeholder="请输入账号"
                v-model="scope.row.bankAccount"
               >  
               </el-input>
             </template>
          </el-table-column>
          <el-table-column label="其他信息" align="center">
            <template slot-scope="scope">
              <el-input
               clearable
               type="text"
               placeholder="请输入其他信息"
               v-model="scope.row.tips"
              >
              </el-input>
            </template>
          </el-table-column>
          <el-table-column label="默认账号" width="250px" align="center">
            <template slot-scope="scope">
              <el-Switch
                v-model="scope.row.defaultAccount"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-value = "1"
                inactive-value = "0"
                active-text="默认账号"
                inactive-text="非默认账号"
                @change="modifyState(scope.row)"
                >
              </el-Switch>
            </template>
          </el-table-column>

          <el-table-column label="状态" width="60px" align="center">
            <template slot-scope="scope">
              <span style="color:#13ce66">{{scope.row.accountStatus == 0 ? '启 用': '禁 用'}}</span>
            </template>
          </el-table-column>

          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-link type="primary" @click="settlementEdit(scope.row)">保 存</el-link> 
              <el-link style="padding: 0 8px" type="success" @click="settlementDisable(scope.row)">
                <span v-if="scope.row.accountStatus == 0">禁 用</span>
                <span v-else>启 用</span>
              </el-link> 
              <el-link type="danger" @click="settlementDelete(scope.$index, scope.row)">删 除</el-link> 
            </template>
          </el-table-column>
         </el-table>

         <!-- 分页区域 -->
         <div class="block" style="textAlign:center; marginTop:20px; paddingRight:20px">
            <el-pagination
              @size-change="handleSizeChange3"
              @current-change="handleCurrentChange3"
              :current-page="pageData.current"
              :page-sizes="[10, 20, 30, 50]"
              :page-size="pageData.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageData.total">
            </el-pagination>
         </div>
       </div>
     </el-dialog>

     <!-- 类型管理 -->
     <el-dialog
      center
      width="800px"
      title="类型管理"
      :visible.sync="managementVisible"
      :modal-append-to-body="false"
     >
      <el-row style="margin-bottom: 10px">
        <span style="font-size: 16px; font-weight: 400">所有类型</span>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6" v-for="(item, index) in dataArr" :key="index" class="el_Col">
          <el-input
           value-key
           @blur="handle_Change(index)"
           :disabled="index<4"
           v-model="item.value"
          ></el-input>
          <i class="el-icon-close i_icon" @click="remove(item.id, index)"></i>
        </el-col>
      </el-row> 
      <el-row>
        <el-col :span="6" style="margin-bottom: 15px">
          <el-button icon="el-icon-plus" type="primary" @click="addType">添加类型</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-button size="medium" @click="managementVisible = fales" style="float: right">取消</el-button>
      </el-row>
     </el-dialog>
   </div>
</template>


<script>
import { 
  getpageInit,
  addSupplier,
  getListPage,
  editSupplier,
  delSupplier,
  ImportFile,
  addSupplierAccount,
  deleteSupplierAccount,
  getListPage2,
  updateAccountStatus,
  getListPage3,
  putSupplierAccount,
  modifyAccountStatus,
  getCategoryInit,
  addOrUpdateCategory,
  deleteCategory,   // 类别管理删除
  uploadFile,   // 上传附件
 } from '@/api/supplier.js'

 function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default {
   data() {
    return {
      editId: "",
      confirmId: "",
      changeId: "", // 做分页使用
      supplierId: "",
      type: 0,   // type: 1 为确定  type: 2 为编辑
      settlementVisible: false,
      accountVisible: false,
      addVisible: false,
      editVisible: false,
      importVisible: false,
      fullscreen:false,
      file: null,
      tableData: [],
      AddAccountList: [],
      settlementTypeList: [],
      accountTypeList: [],
      settlementList: [],
      fileList: [],
      pageInfo: {
        current: 1,
        pageSize: 20,
        total: 0,
        keyword: ""
      },
      pageData: {
        current: 1,
        pageSize: 10,
        total: 0
      }, 
      pageList: {
        current: 1,
        pageSize: 10,
        total: 0
      },
      supplierForm: {  
        supplierName: "",
        supplierCode: "",  // 供货商代码
        settlementType: "",
        companyAddress: "",
        companyTelephone: "",
        contact: "",
        contactTelephone: "",
        website: "",
        tips: "",
        productType: "",  // 产品类型
      },
      editSupplierForm: {
        supplierName: "",
        settlementType: "",
        companyAddress: "",
        companyTelephone: "",
        contact: "",
        contactTelephone: "",
        website: "",
        tips: "",
        supplierCode: "",  // 供应商代码 
        productType: "",
      },
      supplierRules: {
        supplierName: [
          { required: true, message: '供应商名称必填项', trigger: 'change, blur' }
        ],
        settlementType: [
          { required: true, message: '结算方式必填项', trigger: 'change, blur' }
        ],

      },
      editSupplierRules: {
        supplierName: [
          { required: true, message: '供应商名称必填项', trigger: 'change, blur' }
        ],
        settlementType: [
          { required: true, message: '结算方式必填项', trigger: 'change, blur' }
        ],
      },
      productTypeList: [],
      managementVisible: false,
      dataArr: [],
      inputData: '',
      inputId: '',
      fileRaw: null,
      licenseImageId: "",
      fileList2: [],
      fileUrl: "",
      add_fileUrl: "",
      edit_licenseImageId: "",
    }
   },
   created() {
     this.search();  // 页面初始化
   },
   methods: {
    search() {
      let params = {
        current: this.pageInfo.current,
        pageSize: this.pageInfo.pageSize,
        keyword: this.pageInfo.keyword
      }
      getListPage(params)
       .then(res=> {
         if(res.status == 200) {
           this.tableData = [];
           if(res.data.values&&Array.isArray(res.data.values)&&res.data.values.length) {
             this.tableData = res.data.values;
             this.pageInfo.total = res.data.pageInfo.total;
           }
         }
       })
    },
    tableSearch() {
       let params = {
         current: this.pageList.current,
         pageSize: this.pageList.pageSize,
         supplierId: this.supplierId
       }
       getListPage2(params)
       .then(res=> {
         this.AddAccountList = [];
        if(res.status == 200) {
          this.AddAccountList = res.data.data;
          this.pageData.total = res.data.pageInfo.total;
        }else {
          this.AddAccountList = [];
        }
       })
    },
    getInitData() {
      getpageInit()
      .then(res=> {
        if(res.data.code ==200) {
          if(res.data.data.settlementTypeList&&Array.isArray(res.data.data.settlementTypeList)&&res.data.data.settlementTypeList.length) {
            this.settlementTypeList = res.data.data.settlementTypeList;   
          }else {
            this.settlementTypeList = [];
          }
        }
        if(res.data.data.accountTypeList&&Array.isArray(res.data.data.accountTypeList)&&res.data.data.accountTypeList.length) {
           this.accountTypeList = res.data.data.accountTypeList;
        }else {
            this.accountTypeList = [];
        }
        if(res.data.data.productTypeList&&Array.isArray(res.data.data.productTypeList)&&res.data.data.productTypeList.length) {
          this.productTypeList = res.data.data.productTypeList;
        }else {
          this.productTypeList = [];
        }
      })
    },
    // 新增供应商
    Add() {
      this.supplierForm = {
        supplierName: "",
        settlementType: "",
        companyAddress: "",
        companyTelephone: "",
        contact: "",
        contactTelephone: "",
        website: "",
        tips: "",
        supplierCode: "",
        productType: "",
      }
      this.add_fileUrl = "";
      this.fileList2 = [];
      this.getInitData();
      this.addVisible = true;
    },
    Import() {
      this.fileList = [];
      this.importVisible = true;
    },
    nextStep(formName) {
      // 必填项检验
      this.$refs[formName].validate(valid => {
        console.log(valid);
        if(!valid) {
          return false;
        }else {
          let data = {
            companyAddress: this.supplierForm.companyAddress,
            companyTelephone: this.supplierForm.companyTelephone,
            contact: this.supplierForm.contact,
            contactTelephone: this.supplierForm.contactTelephone,
            settlementType: this.supplierForm.settlementType,
            supplierName: this.supplierForm.supplierName,
            tips: this.supplierForm.tips,
            website: this.supplierForm.website,
            supplierCode: this.supplierForm.supplierCode,
            productType: this.supplierForm.productType,
            licenseImageId: this.licenseImageId
          }
          addSupplier(data)
          .then(res=> {
            if(res.data.code == 200) {
              this.supplierId = res.data.data.id;
              this.search();  // 新增列表查询
              this.$message.success(res.data.message);
              this.addVisible = false; 
              this.tableSearch(this.supplierId);
              this.accountVisible = true;
            }
          })
        }
      })
    },
    handleSelectionChange() {},
    // 设置表头
    getRowClass({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return "background:#EFEFEF";
      } else {
        return "";
      }
    },
      rowClass() { },
      handleSizeChange(val) {
        this.pageInfo.pageSize = val;
        this.search();
      },
      handleCurrentChange(val) {
        this.pageInfo.current = val;
        this.search();
      },
      // 新增账户
      addAccount() {
        this.AddAccountList.push({
          accountNo: "",
          accountName: "",
          bank: "",
          accountType: "",
          bankAccount: "",
          defaultAccount: 1,
          accountStatus: 0
        })
      },
      // 结算账户
      asyncSettlement() {
        this.settlementList.push({
          accountNo: "",
          accountName: "",
          accountType: "",
          bank: "",  // 开户机构
          bankAccount: "",
          defaultAccount: 1,
          accountStatus: 0
        })
        // this.type = 1;
      },
      // 修改操作
      asyncEdit(row) {
        this.editSupplierForm.supplierCode = row.supplierCode;
        this.editSupplierForm.productType = String(row.productType);
        this.editSupplierForm.supplierName = row.supplierName;
        this.editSupplierForm.settlementType = row.settlementType;
        this.editSupplierForm.companyAddress = row.companyAddress;
        this.editSupplierForm.companyTelephone = row.companyTelephone;
        this.editSupplierForm.contact = row.contact;
        this.editSupplierForm.contactTelephone = row.companyTelephone;
        this.editSupplierForm.website = row.website;
        this.editSupplierForm.tips = row.tips;
        this.fileUrl = row.licenseImageUrl;
        this.edit_licenseImageId =row.licenseImageId;
        this.editId = row.id;
        this.getInitData();
        this.editVisible = true;
      },
      edit(formName) {
        this.$refs[formName].validate(valid => {
          if(!valid) {
            return false;
          }else {
            let data = {
              companyAddress: this.editSupplierForm.companyAddress,
              companyTelephone: this.editSupplierForm.companyTelephone,
              contact: this.editSupplierForm.contact,
              contactTelephone: this.editSupplierForm.contactTelephone,
              settlementType: this.editSupplierForm.settlementType,
              supplierName: this.editSupplierForm.supplierName,
              supplierCode: this.editSupplierForm.supplierCode,
              productType: this.editSupplierForm.productType,
              tips: this.editSupplierForm.tips,
              website: this.editSupplierForm.website,
              id: this.editId,
              licenseImageId: this.edit_licenseImageId
            }
            editSupplier(data)
            .then(res=> {
              if(res.data.code == 200) {
                this.search();
                this.editVisible = false;
                this.$message.success(res.data.message);
              }else {
                this.$message.error(res.data.message);
              }
            })
          }  
        })
      },
      settlementSearch(id) {
        let params = {
          current: this.pageData.current,
          supplierId: id,
          pageSize: this.pageData.pageSize,
        }
        getListPage3(params)
        .then(res => {
           if(res.status == 200) {
              this.settlementList = [];
             if(res.data.values&&Array.isArray(res.data.values)&&res.data.values.length) {
              this.settlementList = res.data.values;
              this.pageData.total = res.data.pageInfo.total;
             }
           }else {
             this.$message.error(res.data.message);
           }
        })
      },
      // 结算账户
      settlementAccount(id) {
        this.changeId = id;
        this.settlementSearch(id);
        this.getInitData();
        this.settlementVisible = true;
      },
      // 删除操作
      asyncDelete(id) {
        this.$confirm('删除该信息, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(()=> {
          delSupplier(id)
          .then(res=> {
            if(res.data.code == 200) {
              this.search();  // 刷新列表
              this.$message.success(res.data.message);
            }else {
              this.$message.error(res.data.message);
            }
          })
        })
      },
      handleSuccess(res, file, fileList) {
       this.fileList = fileList;
        if(res.data.code == 200) {
          this.$message.success(res.data.message);
        }else if(res.code == 500) {
          this.fileList = [];
          this.$message.error(res.message);
        }
      },
      beforeUpload(file) {},
      importCance() {
        this.importVisible = false;
      },
      handleChange(file, fileList) {
        this.file = file.raw;
      },
      asyncImport() {
        const data = new FormData();
        data.append("file", this.file);
        ImportFile(data)
        .then(res=> {
          if(res.data.code == 200) {
            this.file = null;
            this.search();
            this.$message.success(res.data.message);
            this.importVisible = false;
          }else if(res.data.code == 500) {
            this.$message.error(res.data.message);
          }
        })
      },
      asyncBtn(row) {
        if (!row.accountType) {
          this.$message.error('账户类型不能为空');
          return false;
        }
        let data = {
          accountName: row.accountName,
          accountStatus: row.accountStatus,
          accountType: row.accountType,
          bank: row.bank,
          bankAccount: row.bankAccount,
          defaultAccount: row.defaultAccount,
          supplierId: this.supplierId,
          id: row.id
        }
        if(row.supplierId) {
          putSupplierAccount(data)
          .then(res=> {
            if(res.data.code == 200) {
              this.tableSearch(this.supplierId);
              this.$message.success(res.data.message);
            }else {
              this.$message.error(res.data.message);
            }
          })
        }else {
          addSupplierAccount(data)
          .then(res=> {
            if(res.data.code == 200) {
              this.confirmId = res.data.data.id;
              this.tableSearch(this.supplierId);
              this.$message.success(res.data.message);
            }else {
              this.$message.error(res.data.message);
            }
          })
        }
      }, 
      asyncDisable(status) {
        if(!this.confirmId) {
          this.$message.error('请先保存数据, 再启/禁用');
          return false;
        }
        this.$confirm( `你确定要${status == 1 ? "启用": "禁用"}吗?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(()=> {
          updateAccountStatus(this.confirmId) 
          .then(res=> {
            if(res.data.code == 200) {
              this.tableSearch(this.supplierId);
              this.$message.success(res.data.data.data);
            }
          })
        })
      },
      asyncDelete2(index, row) {
        if(!row.id) {
          this.AddAccountList.splice(index, 1);
        }else {
          this.$confirm('删除该信息, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(()=> {
            deleteSupplierAccount(row.id)
            .then(res=> {
              if(res.data.code == 200) {
                this.tableSearch(this.supplierId);
                this.$message.success(res.data.message);
              }else {
                this.$message.error(res.data.message);
              }
            })
          })
        }
      },
      handleSizeChange2(val) {
        this.pageList.pageSize = val;
        this.tableSearch(this.supplierId);
      },
      handleCurrentChange2(val) {
        this.pageList.current = val;
        this.tableSearch(this.supplierId);
      },
      handleSizeChange3(val) {
        this.pageData.pageSize = val;
        this.settlementSearch(this.changeId);
      },
      handleCurrentChange3(val) {
        this.pageData.current = val;
        this.settlementSearch(this.changeId);
      },
      settlementEdit(row) {
        // this.settlementList.forEach(item => {
        //   console.log(item, 'aaaaaaaaaaaa');
        //   if(item.id == row.id) {
        //     this.type = 1;
        //   }
        // })
        if(!row.accountType) {
          this.$message.error('账户类型不能为空');
          return false;
        }
        let data = {
          accountName: row.accountName,
          accountStatus: row.accountStatus,
          accountType: row.accountType,
          bank: row.bank,
          bankAccount: row.bankAccount,
          defaultAccount: row.defaultAccount,
          id: row.id,
          // supplierId: 12
          tips:row.tips,
          supplierId: this.changeId
        }
        if(row.supplierId) {
          putSupplierAccount(data)
          .then(res=> {
            if(res.data.code == 200) {
              this.settlementSearch(this.changeId);
              this.$message.success(res.data.message);
            }else {
              this.$message.error(res.data.message);
            }
          })
        }else {
          addSupplierAccount(data)
          .then(res=> {
            if(res.data.code == 200) {
              this.settlementSearch(this.changeId);
              this.$message.success(res.data.message);
            }else {
              this.$message.error(res.data.message);
            }
          })
        }
      },
      settlementDisable(row) {
        if(!row.id) {
          this.$message.warning('请保存完数据, 再启/禁用');
          return false;
        }else {
          this.$confirm(`你确定${row.accountStatus == 1 ? "启用": "禁用"}吗?`, "提示", {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(()=> {
            updateAccountStatus(row.id)
            .then(res=> {
               if(res.data.code == 200) {
                 this.settlementSearch(row.supplierId);
                 this.$message.success(res.data.data.data); 
               }else if(res.data.code == 500) {
                this.$message.error(res.data.message); 
               }
            }) 
          })
        }
      },
      settlementDelete(index, row) {
        if(!row.id) {
          this.settlementList.splice(index, 1);
        }else {
          this.$confirm('删除该信息, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(()=> {
            deleteSupplierAccount(row.id)
            .then(res=> {
              if(res.data.code == 200) {
                this.settlementSearch(row.supplierId);
                this.$message.success(res.data.message);
              }else {
                this.$message.error(res.data.message);
              }
            })
          }) 
        }
      },
      // 下载模板
      downloadBtn() {
        window.location.href = "/供应商导入模板.xlsx";
      },
      testPhone(str) {
        const reg = /^1[3|4|5|7|8|6|9][0-9]\d{8}$/;
        return reg.test(str);
      },
      vaildPhone() {
        if(!this.testPhone(this.supplierForm.contactTelephone)) {
          this.$message.error("请输入合法的手机号");
        }
      },
      modifyState(row) {
        if(!row.id) {
          this.$message.error('请先保存完毕数据, 在修改默认账号');
          return false;
        }
        modifyAccountStatus(row.id)
         .then(res=> {
           if(res.data.code == 200) {
             this.$message.success(res.data.data.message);
             this.settlementSearch(this.changeId);
           }else if(res.data.code == 500) {
             this.$message.error(res.data.message);
           }
         })
      },
      find(){
        this.pageInfo.current = 1;
        this.search()
      },
      // 类型管理
      manageType() {
        getCategoryInit()
        .then(res=> {
          if(res.data.code == 200) {
            this.dataArr = res.data.data;
          }else {
            this.dataArr = [];
          }
        })
        this.managementVisible = true;
      },
      // 添加类型
      addType() {
        if(!this.dataArr[this.dataArr.length-1].value) {
          this.$message({
            type: 'warning',
            message: '请填写添加内容'
          })
          return false;
        }
        this.dataArr.push({
          id: '',
          value: ''
        })
      },
      handle_Change(index) {
        for (var i = 0; i < this.dataArr.length; i++) {
          this.inputData = this.dataArr[index].value;
          this.inputId = this.dataArr[index].id;
        }
        let data = {
          id: this.inputId ? this.inputId : 0,
          value: this.inputData
        }
        if(!this.inputData) {
          this.$message({
            type: 'warning',
            message: '请输入内容'
          })
          return false;
        }
        addOrUpdateCategory(data)
         .then(res=> {
           if(res.data.code == 200) {
             this.getInitData();
             this.$message.success(res.data.message);
           }else if(res.data.code == 500){
             this.$message.error(res.data.message);
           }
         })
      },
      // 删除
      async remove(id, _index) {
        if(id) {
          if(_index < 4) {
            return false;
          }else {
            const confirmResult = await this.$confirm("确定删除该类型吗？", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }).catch((err)=> err);
            if(confirmResult !== "confirm") {
              return;
            }
            deleteCategory(id)
             .then(res=> {
               if(res.data.code == 200) {
                 this.$message.success(res.data.message);
                 this.dataArr.splice(_index, 1);
                 this.getInitData();
               }else if(res.data.code == 500) {
                 this.$message.error(res.data.message);
               }
             })
          }
        }else {
          this.dataArr.splice(_index, 1);
        }
      },
      async handleChange2(file, fileList) {
        this.fileRaw = file.raw;
        this.add_fileUrl = await getBase64(this.fileRaw);
        const formdata = new FormData();
        formdata.append("file", this.fileRaw);
        uploadFile(formdata)
         .then(res=> {
           if(res.data.code == 200) {
             this.licenseImageId = res.data.data.fileId;
           }else if(res.data.code == 500) {
             this.$message.error(res.data.message);
           }
        })
      },
      // 上传之前的钩子函数
      beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/png';
        const isJPG2 = file.type === 'image/pdf';
        if (!isJPG) {
          this.$message.error('上传头像图片只能是 PNG 格式!');
        }
        if(!isJPG2) {
          this.$message.error('上传头像图片只能是 PDF  格式!');
        }
        return isJPG && isJPG2;
      },
      async handleChange_Edit(file) {
        const edit_file = file.raw;
        const data2 = new FormData();
        data2.append("file", edit_file);
        this.fileUrl = await getBase64(edit_file);
        uploadFile(data2)
        .then(res=> {
          if(res.data.code == 200) {
            this.edit_licenseImageId = res.data.data.fileId;
          }else if(res.data.code == 500) {
            this.$message.error(res.data.message);
          }
        })
      }
   },
   // 计算属性   
   computed: {
      // 文件导入接口
      action() {
        // let publicPath = 'http://192.168.1.110:8088';
        return process.env.VUE_APP_API_URL+"/api/supplier/addSupplierFile";
      } 
   }
}
</script>

<style lang="scss" scoped>
::v-deep .el-upload-list__item-name {
  width: 360px;
  text-align: center;
}
.el-upload__tip_tip {
  width: 360px;
  text-align: center;
}
.upload-demo {
  text-align: center;
}
.el-upload__tip {
  color: red;
  font-size: 14px;
  text-align: center;
}
.downloadbtnFat {
  height: 40px;
  display: flex;
  justify-content: center;
   margin-bottom: 20px;
}
.downloadbtn {
  background-color: #288a25ff;
  color: #ffffff;
}
.el_Col {
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-start;
  position: relative;
}
.i_icon {
  margin-top: 10px;
  margin-left: 148px;
  float: right;
  cursor: pointer;
  font-size: 15px;
  position: absolute;
}
</style>